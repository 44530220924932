<template>
    <a v-if="url" :href="url.url" :target="url.target">
        <button class="wit-notification-button" :class="{[`wit-notification-button--${variant}`]: true}">
            {{ blok.label }}
        </button>
    </a>
    <span v-else-if="modal">
        <button
            class="wit-notification-button"
            :class="{[`wit-notification-button--${variant}`]: true}"
            @click="showModal = true"
        >
            {{ blok.label }}
        </button>
        <notification-modal :blok="modal" :variant="variant" v-model="showModal"></notification-modal>
    </span>
    <span v-else-if="request">
        <button
            class="wit-notification-button"
            :class="{[`wit-notification-button--${variant}`]: true}"
            @click="buttonRequest"
        >
            {{ blok.label }}
        </button>
    </span>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    props: ['blok', 'variant', 'substituteVariables'],
    components: {
        NotificationModal: () => import('./NotificationModal.vue'),
    },
    data() {
        return {
            showModal: false,
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
        url() {
            return this.blok.onClick[0].component === 'notificationUrl' ? this.blok.onClick[0] : null
        },
        modal() {
            return this.blok.onClick[0].component === 'notificationModal' ? this.blok.onClick[0] : null
        },
        request() {
            return this.blok.onClick[0].component === 'notificationRequest' ? this.blok.onClick[0] : null
        },
    },
    methods: {
        async buttonRequest() {
            this.$store.commit('loading/PROCESSING', this.request.loadingText)

            const url = this.substituteVariables(this.request.url)

            await this.axios({
                method: this.request.method,
                url,
            })

            const {id} = this.activeProject
            await this.$store.dispatch('project/getProjects')

            const newProject = this.$store.state.project.projects.find(el => el.id === id)
            if (newProject) {
                await this.$store.dispatch('project/setProject', newProject)
            }

            this.$store.commit('loading/PROCESSED')
        },
    },
}
</script>

<style lang="scss">
.wit-notification-button {
    background: none;
    border-radius: 15px;
}

.wit-notification-button:focus {
    outline: none;
}

.wit-notification-button--warning {
    border: 1px solid #856404;
    color: #856404;
}

.wit-notification-button--warning:hover {
    background: #856404;
    color: #fff3cd;
}

.wit-notification-button--danger {
    border: 1px solid #721c24;
    color: #721c24;
}

.wit-notification-button--danger:hover {
    background: #721c24;
    color: #f8d7da;
}
</style>
